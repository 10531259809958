import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: 150,
        paddingBottom: 150,
        height: '80vh',
    },
    container: {
        paddingBottom: 100,
    },
}));

const NotFound = () => {
    const classes = useStyles();
    return (
        <Box>
            <Container maxWidth="lg" className={classes.main}>
                <Box>
                    <Typography variant="body1">Error 404</Typography>
                    <Typography variant="h1">
                        No s'ha trobat la pàgina
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default NotFound;
