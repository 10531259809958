import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: 'AIzaSyB4sbH_OJPaEU9Pg31E2tpEgnuuJ9Kn7kw',
    authDomain: 'cavalls-del-vent.firebaseapp.com',
    databaseURL:
        'https://cavalls-del-vent-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'cavalls-del-vent',
    storageBucket: 'cavalls-del-vent.appspot.com',
    messagingSenderId: '689863392182',
    appId: '1:689863392182:web:cd6ae1adafcab435ee9f17',
    measurementId: 'G-WMJL0SKES2',
};

const app = initializeApp(firebaseConfig);
ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
