import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from '../theme/icons/logo_admin_petit.svg';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    SvgIcon,
    Toolbar,
} from '@mui/material';
import { Input, Menu } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {},
    icon: {
        color: 'white',
        width: '90px !important',
        height: '60px !important',
    },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    let navigate = useNavigate();

    const logOut = () => {
        localStorage.setItem('isLoggedIn', 'false');
        navigate('/');
    };

    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    <SvgIcon
                        className={classes.icon}
                        component={Logo}
                        viewBox="0 0 24 13"
                    />
                </RouterLink>
                <Box flexGrow={1} />
                <Hidden>
                    <IconButton color="inherit" onClick={() => logOut()}>
                        <Input />
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={() => onMobileNavOpen()}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

export default TopBar;
