import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const Title = (props) => {
    const { title, button } = props;

    return (
        <Grid container spacing={0}>
            <Grid item lg={12} md={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box my={3}>
                        <Typography variant="h1" gutterBottom>
                            {title}
                        </Typography>
                    </Box>
                    <Box>{button}</Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Title;
