import React from 'react';
import Layout from './layouts/Layout';
import NotFound from './components/NotFound';
import { Navigate } from 'react-router-dom';
import LoginView from './views/Auth/LoginView';
import Xat from './views/Xat/Xat';
import Botiga from './views/Botiga/Botiga';
import ProducteAdd from './views/Botiga/ProducteAdd';
import ProducteEdit from './views/Botiga/ProducteEdit';
import Incidencies from './views/Incidencies/Incidencies';
import FAQs from './views/FAQs/FAQs';
import FAQsAdd from './views/FAQs/FAQsAdd';
import FAQsEdit from './views/FAQs/FAQsEdit';

const routes = (user) => [
    {
        path: '/',
        element: user ? <Layout /> : <Navigate to="/login" />,
        children: [
            { path: '/', element: <Xat /> },
            { path: '/botiga', element: <Botiga /> },
            { path: '/botiga/nou', element: <ProducteAdd /> },
            { path: '/botiga/:id', element: <ProducteEdit /> },
            { path: '/incidencies', element: <Incidencies /> },
            { path: '/faqs', element: <FAQs /> },
            { path: '/faqs/nou', element: <FAQsAdd /> },
            { path: '/faqs/:id', element: <FAQsEdit /> },
            { path: '/*', element: <NotFound /> },
        ],
    },

    {
        path: '/login',
        element: !user ? <LoginView /> : <Navigate to="/" />,
    },
];

export default routes;
