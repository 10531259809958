import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import Page from '../../components/Page';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import Title from '../../components/Title';
import { makeStyles } from '@mui/styles';
import { getDatabase, push, ref } from 'firebase/database';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));

const FAQsAdd = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const crear = async (values) => {
        const db = getDatabase();
        const reference = ref(db, 'faqs/');
        push(reference, {
            pregunta: values.pregunta,
            resposta: values.resposta,
            pregunta_es: values?.pregunta_es,
            resposta_es: values?.resposta_es,
            pregunta_en: values?.pregunta_en,
            resposta_en: values?.resposta_en,
        });
        enqueueSnackbar('Creat amb èxit', {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={'Afegir FAQ'}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    initialValues={{
                        pregunta: '',
                        resposta: '',
                        pregunta_es: '',
                        resposta_es: '',
                        pregunta_en: '',
                        resposta_en: '',
                    }}
                    validationSchema={Yup.object().shape({
                        pregunta: Yup.string().required(
                            'La pregunta és obligatòria'
                        ),
                        resposta: Yup.string().required(
                            'La resposta és obligatòria'
                        ),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mb={3}>
                                <Title
                                    title={'Crear FAQ'}
                                    subtitle={
                                        'Entra les dades per guardar un nou FAQ'
                                    }
                                />
                            </Box>
                            <Typography variant="h3" style={{ marginTop: 20 }}>
                                Català
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.pregunta && errors.pregunta
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.pregunta && errors.pregunta
                                        }
                                        label="Pregunta"
                                        margin="normal"
                                        name="pregunta"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.pregunta}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.resposta && errors.resposta
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.resposta && errors.resposta
                                        }
                                        label={'Resposta'}
                                        margin="normal"
                                        name="resposta"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.resposta}
                                        multiline
                                        rows={5}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="h3" style={{ marginTop: 20 }}>
                                Castellà
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.pregunta_es &&
                                                errors.pregunta_es
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.pregunta_es &&
                                            errors.pregunta_es
                                        }
                                        label="Pregunta"
                                        margin="normal"
                                        name="pregunta_es"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.pregunta_es}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.resposta_es &&
                                                errors.resposta_es
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.resposta_es &&
                                            errors.resposta_es
                                        }
                                        label={'Resposta'}
                                        margin="normal"
                                        name="resposta_es"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.resposta_es}
                                        multiline
                                        rows={5}
                                    />
                                </Grid>
                            </Grid>
                            <Typography variant="h3" style={{ marginTop: 20 }}>
                                Anglès
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.pregunta_en &&
                                                errors.pregunta_en
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.pregunta_en &&
                                            errors.pregunta_en
                                        }
                                        label="Pregunta"
                                        margin="normal"
                                        name="pregunta_en"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.pregunta_en}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.resposta_en &&
                                                errors.resposta_en
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.resposta_en &&
                                            errors.resposta_en
                                        }
                                        label={'Resposta'}
                                        margin="normal"
                                        name="resposta_en"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.resposta_en}
                                        multiline
                                        rows={5}
                                    />
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {'Crear'}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default FAQsAdd;
