import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import theme from './theme';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { Grow, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

// import { getUser } from './database/API';

function App() {
    const [user, setUser] = useState(false);
    const routing = useRoutes(routes(user));
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    useEffect(() => {
        const loggedInUser = localStorage.getItem('isLoggedIn');
        if (loggedInUser === 'false') {
            setUser(false);
        } else {
            setUser(true);
        }
    }, [routing]);

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                TransitionComponent={Grow}
                ref={notistackRef}
                action={(key) => (
                    <IconButton onClick={onClickDismiss(key)}>
                        <Close style={{ color: 'white' }} />
                    </IconButton>
                )}
            >
                {routing}
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
