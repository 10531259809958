import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%',
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
    },
    title: {
        marginRight: 'auto',
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '& $title': {
            fontWeight: '700',
        },
        '& $icon': {
            color: 'white',
        },
        '&:hover': {
            color: theme.palette.text.secondary,
            '& $icon': {
                color: theme.palette.text.secondary,
            },
        },
    },
}));

const NavBarItem = ({
    className,
    href,
    icon: Icon,
    title,
    onClose,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <ListItem
            className={clsx(classes.item, className)}
            disableGutters
            {...rest}
        >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                component={RouterLink}
                to={href}
                onClick={onClose}
            >
                <Icon className={classes.icon} size="20" />

                <span className={classes.title}>{title}</span>
            </Button>
        </ListItem>
    );
};

NavBarItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavBarItem;
