import { ChatBubbleOutlineOutlined } from '@mui/icons-material';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Chip,
    Badge,
    ListItemIcon,
    Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    getDatabase,
    onValue,
    orderByChild,
    push,
    query,
    ref,
    update,
} from 'firebase/database';
import React, { useEffect, useState } from 'react';
import XatComponent from './XatComponent';
import _uniqueId from 'lodash/uniqueId';
import { sendNotificationEXPO } from '../../components/API';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        height: '100%',
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    xat: {
        flex: 5,
        display: 'flex',
        flexDirection: 'column',
    },
    usuaris: {
        flex: 3,
        overflowX: "scroll"
    },
    wrap: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    xatHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const Xat = () => {
    const [messages, setMessages] = useState([]);
    const [xats, setXats] = useState([]);
    const [token, setToken] = useState('');
    const [unread, setUnread] = useState(false);
    const [xatSelected, setXatSelected] = useState();
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const db = getDatabase();

    useEffect(() => {
        const reference = query(ref(db, 'users'), orderByChild('updateAt'));
        const unsubscribe = onValue(reference, (snapshot) => {
            let result = [];

            snapshot.forEach((item) => {
                result.push({ ...item.val(), id: item.key });
            });


            setXats(result);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        setLoading(true);

        const reference = query(ref(db, 'xat/' + xatSelected?.id));
        const reference_user = query(ref(db, 'users/' + xatSelected?.id));
        const unsubscribe = onValue(reference, async (snapshot) => {
            if (xatSelected) {
                let result = [];

                await snapshot.forEach((item) => {
                    result.push({ ...item.val(), id: item.key });
                });

                setMessages(result);
                setLoading(false);
                update(reference_user, {
                    updateAt: String(new Date()),
                    read: true,
                });
            }
        });

        const getToken = onValue(reference_user, async (snapshot) => {
            if (xatSelected) {
                setToken(snapshot.val().token);
            }
        });

        getToken();
        return () => unsubscribe();
    }, [xatSelected]);

    const onSend = async (message) => {
        const reference = ref(db, 'xat/' + xatSelected?.id);
        push(reference, {
            _id: _uniqueId('ID-'),
            createdAt: String(new Date()),
            text: message,
            user: {
                _id: 2,
                name: 'Cavalls del Vent',
                avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVMHtQUprpF45ErQRZZIonSaEGQNJxiLQTCQ&usqp=CAU',
            },
        });
        const result = await sendNotificationEXPO(token, message);
        console.log(result);
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.wrap}>
                <div className={classes.xat}>
                    <Box>
                        <Box p={2} className={classes.xatHeader}>
                            <Typography variant="h3">
                                {xatSelected
                                    ? xatSelected?.nom
                                    : 'Sel·lecciona un usuari'}
                            </Typography>
                            <Typography variant="h5">
                                {xatSelected?.id}
                            </Typography>
                        </Box>
                        <Divider />
                    </Box>

                    <XatComponent
                        messages={messages}
                        onSend={(messages) => onSend(messages)}
                        user={xatSelected}
                        loading={loading}
                    />
                </div>
                <Divider orientation="vertical" />
                <Box className={classes.usuaris}>
                    <Box p={2}>
                        <Typography variant="h3">Usuaris</Typography>
                    </Box>
                    <List style={{ padding: 0 }}>
                        <Divider />

                        {xats?.map((item) => (
                            <ListItem
                                disablePadding
                                secondaryAction={<Chip label={item?.lang} />}
                                divider
                            >
                                <ListItemButton
                                    onClick={() => setXatSelected(item)}
                                    sx={{
                                        backgroundColor:
                                            item?.id === xatSelected?.id
                                                ? 'rgba(0, 0, 0, 0.09)'
                                                : '',
                                    }}
                                >
                                    <ListItemIcon>
                                        <Badge
                                            variant="dot"
                                            color="primary"
                                            invisible={item?.read}
                                        >
                                            <ChatBubbleOutlineOutlined color="action" />
                                        </Badge>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item?.nom}
                                        secondary={item?.id}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </Box>
    );
};

export default Xat;
