import {
    Box,
    Button,
    CircularProgress,
    Fade,
    Input,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '93%',
    },
    xat: {
        justifyContent: 'flex-end',
        paddingRight: 10,
        paddingLeft: 10,
        overflowY: 'scroll',
        flex: 1,
    },
    user: {
        backgroundColor: theme.palette.text.secondary,
    },
    me: {
        backgroundColor: theme.palette.text.primary,
        marginRight: 0,
        marginLeft: 'auto',
        textAlign: 'right',
    },
    bubble: {
        borderRadius: 20,
        padding: 10,
        marginBottom: 10,
        marginTop: 10,
        display: 'table',
    },
    text: {
        color: 'white !important',
    },
    inputWrap: {
        backgroundColor: 'white',
        padding: 10,
        display: 'flex',
    },

    date: {
        color: 'white !important',
        opacity: 0.5,
        fontSize: '10px !important',
    },
    textMe: {
        textAlign: 'right',
    },
    loading: {
        marginBottom: 60,
        textAlign: 'center',
    },
}));

const StyledInputElement = styled('input')(
    ({ theme }) =>
        `font-size: 0.875rem;
    font-family: Ubuntu, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: black;
    background: white;
    border: 1px solid #cacaca;
    border-radius: 50px;
    padding: 12px 12px;
    display: flex;
    flex: 1;
    &:hover {
      background: white;
      border-color: #cacaca;
    }
  
    &:focus {
      outline: 2px solid #cacaca;
    }`
);

const XatComponent = ({ messages, onSend, user, loading }) => {
    const classes = useStyles();
    const [message, setMessage] = useState('');
    const xatDiv = useRef();

    const change = (e) => {
        setMessage(e.target.value);
    };

    const send = (value) => {
        onSend(value);
        setMessage('');
    };

    useEffect(() => {
        xatDiv.current?.scrollIntoView();
    }, [messages, loading]);

    return (
        <Box className={classes.container}>
            {!loading ? (
                <Fade in={!loading}>
                    <Box className={classes.xat}>
                        {messages.map((item) => {
                            const date = moment(
                                new Date(item.createdAt)
                            ).format('DD/MM/Y HH:mm');
                            return (
                                <Box
                                    className={clsx(
                                        item?.user?._id === user?.id
                                            ? classes.user
                                            : classes.me,
                                        classes.bubble
                                    )}
                                >
                                    <Typography className={classes.text}>
                                        {item.text}
                                    </Typography>
                                    <Typography
                                        className={clsx(
                                            classes.date,
                                            item?.user?._id !== user?.id &&
                                                classes.textMe
                                        )}
                                    >
                                        {date}
                                    </Typography>
                                </Box>
                            );
                        })}
                        <div ref={xatDiv} />
                    </Box>
                </Fade>
            ) : (
                <Box className={clsx(classes.loading, classes.xat)}>
                    <CircularProgress />
                </Box>
            )}
            <Box className={classes.inputWrap}>
                <StyledInputElement
                    onChange={change}
                    value={message}
                    onKeyDown={(value) => {
                        if (value.keyCode === 13) {
                            send(value.target.value);
                        }
                    }}
                    disabled={!user}
                />
                <Button disabled={!user} onClick={() => send(message)}>
                    Enviar
                </Button>
            </Box>
        </Box>
    );
};

export default XatComponent;
