import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import Page from '../../components/Page';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Fade,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import Title from '../../components/Title';
import Thumb from '../../components/Thumb';
import { makeStyles } from '@mui/styles';
import { getDatabase, onValue, push, ref, update } from 'firebase/database';
import { useParams, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));

const ProducteEdit = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [producte, setProducte] = useState();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const db = getDatabase();
        const reference = ref(db, 'botiga/' + id);
        const unsubscribe = onValue(reference, (snapshot) => {
            setProducte(snapshot.val());
            setLoading(false);
        });
        return () => unsubscribe();
    }, [id]);

    const crear = async (values) => {
        const db = getDatabase();
        const reference = ref(db, 'botiga/' + id);
        update(reference, {
            imatge: values.imatge,
            url: values.url,
            preu: values.preu,
            order: values.order,
            nom: values.nom,
        });
        enqueueSnackbar('Actualitzat amb èxit', {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={'Editar producte'}>
            <Container maxWidth={false} className={classes.container}>
                <Box mb={3}>
                    <Title
                        title={'Editar producte'}
                        subtitle={'Entra les dades per editar el producte'}
                    />
                </Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        nom: producte?.nom,
                        preu: producte?.preu,
                        url: producte?.url,
                        order: producte?.order,
                        imatge: producte?.imatge,
                    }}
                    validationSchema={Yup.object().shape({
                        nom: Yup.string().required('El nom és obligatòri'),
                        preu: Yup.number().required('El preu és obligatòri'),
                        url: Yup.string().required('La URL és obligatòria'),
                        imatge: Yup.string().required(
                            'La imatge és obligatòria'
                        ),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                    }) =>
                        loading ? (
                            <CircularProgress />
                        ) : (
                            <Fade in={!loading}>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.nom && errors.nom
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.nom && errors.nom
                                                }
                                                label="Nom"
                                                margin="normal"
                                                name="nom"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.nom}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.preu && errors.preu
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.preu && errors.preu
                                                }
                                                label={'Preu'}
                                                margin="normal"
                                                name="preu"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                value={values.preu}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.order &&
                                                        errors.order
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.order &&
                                                    errors.order
                                                }
                                                label={
                                                    'Ordre (de petit a gran)'
                                                }
                                                margin="normal"
                                                name="order"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                value={values.order}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.imatge &&
                                                        errors.imatge
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.imatge &&
                                                    errors.imatge
                                                }
                                                label={'URL Imatge'}
                                                margin="normal"
                                                name="imatge"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.imatge}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.url && errors.url
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.url && errors.url
                                                }
                                                label={'URL Enllaç'}
                                                margin="normal"
                                                name="url"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.url}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box my={2}>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {'Guardar'}
                                        </Button>
                                    </Box>
                                </form>
                            </Fade>
                        )
                    }
                </Formik>
            </Container>
        </Page>
    );
};

export default ProducteEdit;
