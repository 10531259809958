import { getDatabase, ref, remove } from 'firebase/database';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const IncidenciesOptions = (incidencies, setIncidenciaID) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const options = {
        enableNestedDataAccess: '.',
        filter: false,
        download: false,
        print: false,
        filterType: 'checkbox',
        onRowClick: (rowData) => {
            setIncidenciaID(rowData[0]);
            // navigate(`/incidencies/${rowData[0]}`);
        },
        onRowsDelete: (rowsDeleted) => {
            rowsDeleted.data.forEach(async (item) => {
                const db = getDatabase();
                const reference = ref(
                    db,
                    'incidencia/' + incidencies[item.dataIndex].id
                );
                await remove(reference);

                enqueueSnackbar('Incidència eliminada', {
                    variant: 'success',
                });
            });
        },
        textLabels: {
            body: {
                noMatch: 'No hi ha incidencies',
                toolTip: 'Ordenar',
                columnHeaderTooltip: (column) =>
                    `${'Ordenat per'} ${column.label}`,
            },
            pagination: {
                next: 'Següent pàgina',
                previous: 'Pàgina anterior',
                rowsPerPage: 'Files per pàgina:',
                displayRows: 'de', // 1-10 of 30
            },
            toolbar: {
                search: 'Cercar',
                downloadCsv: 'Descarregar CSV',
                print: 'Imprimir',
                viewColumns: 'Veure columnes',
                filterTable: 'Filtrar taula',
            },
            filter: {
                title: 'FILTRES',
                reset: 'resetejar',
                all: 'Tots',
            },
            viewColumns: {
                title: 'Mostrar columnes',
            },
            selectedRows: {
                text: 'Fila/es eliminades',
                delete: 'Eliminar',
            },
        },
    };
    return options;
};

const columns = [
    {
        name: 'id',
        label: 'ID',
        options: {
            filter: false,
            sort: true,
            display: false,
        },
    },
    {
        name: 'nom',
        label: 'Nom',
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: 'mail',
        label: 'E-mail',
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: 'assumpte',
        label: 'Assumpte',
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: 'reserva',
        label: 'Nº Reserva',
        options: {
            filter: false,
            sort: true,
        },
    },
];

export { IncidenciesOptions, columns };
