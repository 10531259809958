import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Page = forwardRef(({ children, title = '', ...rest }, ref) => {
    return (
        <HelmetProvider>
            <div ref={ref} {...rest}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {children}
            </div>
        </HelmetProvider>
    );
});

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
};

export default Page;
