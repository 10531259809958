import { getDatabase, remove } from 'firebase/database';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { ref } from 'yup';

const FAQsOptions = (faqs) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const options = {
        enableNestedDataAccess: '.',
        filter: false,
        download: false,
        print: false,
        filterType: 'checkbox',
        onRowClick: (rowData) => {
            navigate(`/faqs/${rowData[0]}`);
        },
        onRowsDelete: (rowsDeleted) => {
            rowsDeleted.data.forEach(async (item) => {
                const db = getDatabase();
                const reference = ref(db, 'faqs/' + faqs[item.dataIndex].id);
                await remove(reference);

                enqueueSnackbar('FAQ eliminat', {
                    variant: 'success',
                });
            });
        },
        textLabels: {
            body: {
                noMatch: 'No hi ha FAQs',
                toolTip: 'Ordenar',
                columnHeaderTooltip: (column) =>
                    `${'Ordenat per'} ${column.label}`,
            },
            pagination: {
                next: 'Següent pàgina',
                previous: 'Pàgina anterior',
                rowsPerPage: 'Files per pàgina:',
                displayRows: 'de', // 1-10 of 30
            },
            toolbar: {
                search: 'Cercar',
                downloadCsv: 'Descarregar CSV',
                print: 'Imprimir',
                viewColumns: 'Veure columnes',
                filterTable: 'Filtrar taula',
            },
            filter: {
                title: 'FILTRES',
                reset: 'resetejar',
                all: 'Tots',
            },
            viewColumns: {
                title: 'Mostrar columnes',
            },
            selectedRows: {
                text: 'Fila/es eliminades',
                delete: 'Eliminar',
            },
        },
    };
    return options;
};

const columns = [
    {
        name: 'id',
        label: 'ID',
        options: {
            filter: false,
            sort: true,
            display: false,
        },
    },
    {
        name: 'pregunta',
        label: 'Pregunta',
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: 'resposta',
        label: 'Resposta',
        options: {
            filter: false,
            sort: true,
        },
    },
];

export { FAQsOptions, columns };
