import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import {
    getDatabase,
    ref,
    onValue,
    remove,
    query,
    orderByChild,
} from 'firebase/database';
import {
    CircularProgress,
    Container,
    Fade,
    IconButton,
    Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Title from '../../components/Title';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { BotigaOptions, columns } from './BotigaTable';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const Botiga = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [productes, setProductes] = useState([]);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const options = BotigaOptions(productes);

    useEffect(() => {
        const db = getDatabase();
        const reference = query(ref(db, 'botiga'), orderByChild('order'));
        // const reference = ref(db, 'botiga');
        const unsubscribe = onValue(reference, (snapshot) => {
            let result = [];

            snapshot.forEach((item) => {
                result.push({ ...item.val(), id: item.key });
            });
            setProductes(result);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    return (
        <Page className={classes.root} title={'Botiga'}>
            <Container maxWidth={false} className={classes.container}>
                <Title
                    title="Botiga"
                    button={
                        <Tooltip title="Afegir producte">
                            <IconButton
                                className={classes.addButton}
                                onClick={() => navigate('nou')}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    }
                />
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Fade in={!loading}>
                        <div>
                            <MUIDataTable
                                data={productes}
                                columns={columns}
                                options={options}
                            />
                        </div>
                    </Fade>
                )}
            </Container>
        </Page>
    );
};

export default Botiga;
