import React, { useEffect, useRef, useState } from 'react';
import Page from '../../components/Page';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import { DataGrid } from '@mui/x-data-grid';
import {
    Avatar,
    Box,
    CircularProgress,
    Container,
    Fade,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Title from '../../components/Title';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { IncidenciesOptions, columns } from './IncidenciesTable';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const Incidencies = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [incidencies, setIncidencies] = useState([]);
    const [incidenciaID, setIncidenciaID] = useState([]);
    const [incidencia, setIncidencia] = useState([]);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const options = IncidenciesOptions(incidencies, setIncidenciaID);

    useEffect(() => {
        const db = getDatabase();
        const reference = ref(db, 'incidencia/' + incidenciaID);
        const unsubscribe = onValue(reference, (snapshot) => {
            setIncidencia(snapshot.val());
            setLoading(false);
        });
        return () => unsubscribe();
    }, [incidenciaID]);

    useEffect(() => {
        const db = getDatabase();
        const reference = ref(db, 'incidencia');
        const unsubscribe = onValue(reference, (snapshot) => {
            let result = [];

            snapshot.forEach((item) => {
                result.push({ ...item.val(), id: item.key });
            });
            setIncidencies(result);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    return (
        <Page className={classes.root} title={'Incidències'}>
            <Container maxWidth={false} className={classes.container}>
                <Title title="Incidències" />
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Fade in={!loading}>
                                <div>
                                    <MUIDataTable
                                        data={incidencies}
                                        columns={columns}
                                        options={options}
                                    />
                                </div>
                            </Fade>
                        )}
                    </Grid>
                    <Grid item md={6}>
                        <Paper>
                            <Box p={5}>
                                <Typography variant="h3">
                                    Incidència sel·leccionada
                                </Typography>
                                <Box my={2}>
                                    <Typography variant="caption">
                                        Nom
                                    </Typography>
                                    <Typography variant="body1">
                                        {incidencia?.nom}
                                    </Typography>
                                </Box>

                                <Box my={2}>
                                    <Typography variant="caption">
                                        E-mail
                                    </Typography>
                                    <Typography variant="body1">
                                        {incidencia?.mail}
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Typography variant="caption">
                                        Assumpte
                                    </Typography>
                                    <Typography variant="body1">
                                        {incidencia?.assumpte}
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Typography variant="caption">
                                        Missatge
                                    </Typography>
                                    <Typography variant="body1">
                                        {incidencia?.missatge}
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default Incidencies;
