import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import Page from '../../components/Page';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Fade,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import Title from '../../components/Title';
import { makeStyles } from '@mui/styles';
import { getDatabase, onValue, ref, update } from 'firebase/database';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));

const FAQsEdit = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [faq, setFaq] = useState();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const db = getDatabase();
        const reference = ref(db, 'faqs/' + id);
        const unsubscribe = onValue(reference, (snapshot) => {
            setFaq(snapshot.val());
            setLoading(false);
        });
        return () => unsubscribe();
    }, [id]);

    const crear = async (values) => {
        const db = getDatabase();
        const reference = ref(db, 'faqs/' + id);
        console.log(id);
        console.log(values);
        update(reference, {
            pregunta: values.pregunta,
            resposta: values.resposta,
            pregunta_es: values?.pregunta_es,
            resposta_es: values?.resposta_es,
            pregunta_en: values?.pregunta_en,
            resposta_en: values?.resposta_en,
        });
        enqueueSnackbar('Creat amb èxit', {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={'Editar FAQ'}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        pregunta: faq?.pregunta,
                        resposta: faq?.resposta,
                        pregunta_es: faq?.pregunta_es,
                        resposta_es: faq?.resposta_es,
                        pregunta_en: faq?.pregunta_en,
                        resposta_en: faq?.resposta_en,
                    }}
                    validationSchema={Yup.object().shape({
                        pregunta: Yup.string().required(
                            'La pregunta és obligatòria'
                        ),
                        resposta: Yup.string().required(
                            'La resposta és obligatòria'
                        ),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                    }) =>
                        loading ? (
                            <CircularProgress />
                        ) : (
                            <Fade in={!loading}>
                                <form onSubmit={handleSubmit}>
                                    <Box mb={3}>
                                        <Title
                                            title={'Editar FAQ'}
                                            subtitle={
                                                'Entra les dades per ediar un FAQ'
                                            }
                                        />
                                    </Box>
                                    <Typography
                                        variant="h3"
                                        style={{ marginTop: 20 }}
                                    >
                                        Català
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.pregunta &&
                                                        errors.pregunta
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.pregunta &&
                                                    errors.pregunta
                                                }
                                                label="Pregunta"
                                                margin="normal"
                                                name="pregunta"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.pregunta}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.resposta &&
                                                        errors.resposta
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.resposta &&
                                                    errors.resposta
                                                }
                                                label={'Resposta'}
                                                margin="normal"
                                                name="resposta"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.resposta}
                                                multiline
                                                rows={5}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Typography
                                        variant="h3"
                                        style={{ marginTop: 20 }}
                                    >
                                        Castellà
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.pregunta_es &&
                                                        errors.pregunta_es
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.pregunta_es &&
                                                    errors.pregunta_es
                                                }
                                                label="Pregunta"
                                                margin="normal"
                                                name="pregunta_es"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.pregunta_es}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.resposta_es &&
                                                        errors.resposta_es
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.resposta_es &&
                                                    errors.resposta_es
                                                }
                                                label={'Resposta'}
                                                margin="normal"
                                                name="resposta_es"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.resposta_es}
                                                multiline
                                                rows={5}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography
                                        variant="h3"
                                        style={{ marginTop: 20 }}
                                    >
                                        Anglès
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.pregunta_en &&
                                                        errors.pregunta_en
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.pregunta_en &&
                                                    errors.pregunta_en
                                                }
                                                label="Pregunta"
                                                margin="normal"
                                                name="pregunta_en"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.pregunta_en}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.resposta_en &&
                                                        errors.resposta_en
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.resposta_en &&
                                                    errors.resposta_en
                                                }
                                                label={'Resposta'}
                                                margin="normal"
                                                name="resposta_en"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.resposta_en}
                                                multiline
                                                rows={5}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box my={2}>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {'Editar'}
                                        </Button>
                                    </Box>
                                </form>
                            </Fade>
                        )
                    }
                </Formik>
            </Container>
        </Page>
    );
};

export default FAQsEdit;
