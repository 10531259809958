import axios from 'axios';

const sendNotificationEXPO = async (token, message) => {
    const url = 'https://api.lacabanaberga.cat/api/send';
    let result;
    let config = {
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    };
    const data = {
        to: token,
        title: 'Nou missatge',
        sound: 'default',
        body: message,
    };

    await axios.post(url, JSON.stringify(data), config).then((response) => {
        if (response.status === 200) {
            result = 'Notificació enviada';
            console.log(response);
        }
        if (
            response.data.status === 'failed' &&
            response.data.success === undefined
        ) {
            result = 'Error al enviar notificació';
        }
    });

    return result;
};

export { sendNotificationEXPO };
